exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cover-letter-tsx": () => import("./../../../src/pages/cover-letter.tsx" /* webpackChunkName: "component---src-pages-cover-letter-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-page-project-tsx": () => import("./../../../src/templates/page/project.tsx" /* webpackChunkName: "component---src-templates-page-project-tsx" */),
  "component---src-templates-page-resume-tsx": () => import("./../../../src/templates/page/resume.tsx" /* webpackChunkName: "component---src-templates-page-resume-tsx" */),
  "component---src-templates-social-image-index-tsx": () => import("./../../../src/templates/social-image/index.tsx" /* webpackChunkName: "component---src-templates-social-image-index-tsx" */),
  "component---src-templates-social-image-other-tsx": () => import("./../../../src/templates/social-image/other.tsx" /* webpackChunkName: "component---src-templates-social-image-other-tsx" */),
  "component---src-templates-social-image-project-tsx": () => import("./../../../src/templates/social-image/project.tsx" /* webpackChunkName: "component---src-templates-social-image-project-tsx" */)
}

